<template>
  <div class="page-header align-items-start" :style="styleObject">
    <span class="mask bg-white"></span>
    <div class="container p-0">
      <div class="row">
        <div class="col-lg-12 col-md-8 col-12 mx-auto">
          <div class="card-body ps-0 pe-0 pb-90">
            <div
              class="pb-0 font-weight-bolder h4 px-2"
              :style="`color: ${this.$store.state.labelColor} !important`"
            >
              Booking
            </div>
            <div class="row d-flex justify-content-center mx-2">
              <ul class="booking-tab ps-0 pe-0" :style="styleObject">
                <li
                  class="nav-item active"
                  id="nav_item_1"
                  @click="changeType(1)"
                  :style="`color: ${this.$store.state.labelColor} !important`"
                >
                  <strong>Booking</strong>
                </li>
                <li
                  class="nav-item"
                  id="nav_item_2"
                  @click="changeType(2, 1)"
                  :style="`color: ${this.$store.state.labelColor} !important`"
                >
                  <strong>Active</strong>
                </li>
                <li
                  class="nav-item"
                  id="nav_item_3"
                  @click="changeType(3, 2)"
                  :style="`color: ${this.$store.state.labelColor} !important`"
                >
                  <strong>History</strong>
                </li>
              </ul>
            </div>
            <div v-if="isLoadDone">
              <div class="mt-3 " v-if="type == 1">
                <div class="d-flex s-branch align-items-center px-2">
                  <Multiselect
                    v-model="branchIdSelect"
                    :options="branches"
                    placeholder="Select branch"
                    :canClear="false"
                    @change="selectBranch"
                  />
                </div>
                <div class="list-venue mt-3 px-2" v-if="venues.length">
                  <div class="venue-info mb-2" v-for="(item, index) in venues" :key="index" @click="selectVenue(item.id)">
                    <img :src="item.image" alt="error">
                    <div class="ms-3 text-title">{{ item.name }}</div>
                  </div>
                </div>
                <div class="mt-3" v-else>
                  <div class="d-flex justify-content-center p-5" style="background-color: #f0f2f5;">No
                      venues available
                  </div>
                </div>
              </div>
              <div v-else class="mt-3" >
                <div class="booking-list px-2" v-if="bookingList.length">
                  <div class="booking-box mb-2" v-for="(item, index) in bookingList" :key="index">
                    <div class="d-flex justify-content-between text-title">
                      <div>#{{ item.booking_code }}</div>
                      <div class="d-flex align-content-center">{{ item.price > 0 ? item.price_format : '' }}  <i class="material-icons-round opacity-10 ms-2">check_circle</i></div>
                    </div>
                    <div class="mt-3 text-center">
                      <div>{{ formatDate(item.booking_date) }}</div>
                      <div>{{ item.time_text }}</div>
                      <div>{{ item.venue_name + ' (' + item.type_name + ')' }}</div>
                    </div>
                  </div>
                </div>
                <div class="mt-3" v-else>
                  <div class="d-flex justify-content-center p-5" style="background-color: #f0f2f5;">No
                      timeslot available
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
    <ModalConfirmDonate
      :amount="donateAmount"
      btnYes="Yes"
      :sycn-profile="processPayment"
    />
  </div>
</template>

<script>
import MaterialLoading from "@/components/MaterialLoading.vue";
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import BookingService from "../services/booking.service";
import "bootstrap/dist/js/bootstrap.bundle";
import ModalConfirmDonate from "@/components/ModalConfirmDonate.vue";
import Multiselect from "@vueform/multiselect";
import $ from "jquery";
import moment from "moment";
import MemberService from "../services/member.service";
import { getMerchantCode } from "@/config/utils";

export default {
  name: "my-transacions",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    ModalConfirmDonate,
    Multiselect
  },
  data() {
    return {
      isLoadDone: false,
      merchantCode: "",
      loaderActive: false,
      snackbar: "",
      message: "",
      type: 1,
      dateSelect: null,
      venues: [],
      branches: [],
      branchIdSelect: this.$route.query.branch_id ?? 0,
      keyReloadSelectTimeSlot: 'keyReloadSelectTimeSlot',
      bookingList: [],
    };
  },
  async created() {
    this.isLoadDone = false;
    this.getMemberInfo();
    await this.getBranches();

    if (this.branchIdSelect) {
      await this.getListVenue();
    }
    this.isLoadDone = true;
  },
  mounted() {
    const code = getMerchantCode();
    this.merchantCode = code ? `/${code}` : "";
  },
  computed: {
    styleObject() {
      return {
        "--button-color": this.$store.state.buttonColor,
        "--label-color": this.$store.state.labelColor
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),

    formatDate(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },

    changeType(type, history_type = 1) {
      this.type = type;
      this.branches = [];
      this.branchIdSelect = 0;
      this.venues = [];

      $('ul.booking-tab >li').removeClass('active');
      $('#nav_item_' + type).addClass("active")

      if (type == 1) {
        this.getBranches();
      } else {
        this.memberBookingHistory(history_type);
      }
    },

    async getBranches() {
      this.showLoader();
      await BookingService.getBranches().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branches = response.data.data;

            if (this.branches.length > 0) {
              this.branchIdSelect = this.branches[0].value ?? 0;
            }
          } else {
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    selectBranch(branchId) {
      this.branchIdSelect = branchId;
      this.getListVenue();
    },

    async getListVenue() {
      this.showLoader();
      var dataForm = {
        branch_id: this.branchIdSelect,
      };

      await BookingService.getListVenue(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.venues = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    memberBookingHistory(type) {
      this.bookingList = [];
      this.showLoader();
      var dataForm = {
        type: type,
      };

      BookingService.memberBookingHistory(dataForm).then(
        (response) => {
          console.log(response);
          if (response.data.result.isSuccess) {
            this.bookingList = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    selectVenue(id) {
      this.$router.push(this.merchantCode + '/booking/venue/' + id + '?branch_id=' + this.branchIdSelect);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    getMemberInfo() {
      MemberService.getProfile().then(
          (response) => {
              if (response.data.result.isSuccess) {
                if(response.data.data && !response.data.data.phone_number) {
                  this.$router.push(this.merchantCode + "/profile");
                }
              }
          },
          (error) => {
            console.log("error", error);
          }
      );
    },
  },
};
</script>
<style scoped>
.page-header {
  height: calc(100vh - 56px);
}
.booking-tab {
  margin: 0px !important;
  padding: 0px;
  width: 100% !important;
}

.booking-tab li {
  list-style: none;
  float: left;
  width: calc(100% / 3);
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.booking-tab.show-point-transaction li {
  width: calc(100% / 2) !important;
}

.booking-tab li.active {
  border-bottom: 2px solid var(--label-color);
  color: var(--label-color);
}
.price-bold {
  font-weight: bold;
  font-size: 20px;
  padding-right: 10px;
}
.booking-tab li {
  border-bottom: 2px solid rgb(238, 234, 234);
}

.venue-info {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.venue-info img {
  width: 70px;
  height: 70px;
}

.text-title {
  font-size: 16px !important;
}

.booking-list {
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.booking-box {
  padding: 5px 10px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.booking-box i {
  color: #3aac5d;
}
</style>
