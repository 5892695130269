import { createRouter, createWebHistory } from "vue-router";
import SignIn from "../views/SignIn.vue";
import TermServiceDetail from "../views/TermServiceDetail.vue";
import PrivacyPolicyDetail from "../views/PrivacyPolicyDetail.vue";
// import Profile from "../views/Profile.vue";
import LoyaltyTierBenefit from "../views/LoyaltyTierBenefit.vue";
import MyTransactions from "../views/MyTransactions.vue";
import TransactionDetails from "../views/TransactionDetails.vue";
import Landing from "../views/Landing.vue";
import rewardDetail from "../views/rewardDetail.vue";
import voucherBundleDetail from "../views/VoucherBundleDetail.vue";
// import Notification from "../views/Notification.vue";
import MyRewards from "../views/MyRewards.vue";
import MyCampaigns from "../views/MyCampaigns.vue";
import Store from "../views/Store.vue";
import InviteFriends from "../views/InviteFriends.vue";
import FindStores from "../views/FindStores.vue";
import RewardCatalogue from "../views/RewardCatalogue.vue";
import ContactUs from "../views/ContactUs.vue";
import Topup from "../views/Topup.vue";
import Donation from "../views/Donation.vue";
import ForgotPassword from "../views/ForgotPassword"
import MyProfile from '../views/MyProfile'
import GetMemberShip from '../views/GetMemberShip'
import checkMemberPermissions from './middleware';
import GetMemberShipSuccess from '../views/GetMemberShipSuccess'
import ChangePasscode from "../views/ChangePasscode.vue";
import Booking from "../views/Booking.vue";
import VenueDetail from "../views/VenueDetail.vue";
import BookingCompleted from "../views/BookingCompleted.vue";
import Admissions from "../views/Admissions.vue";
import store from "@/store";

const routes = [
  {
    path: "/:merchant_code/top-up",
    name: "Top-up",
    component: Topup,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code/donation",
    name: "Donation",
    component: Donation,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code/booking",
    name: "Booking",
    component: Booking,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code/booking/venue/:id",
    name: "VenueDetail",
    component: VenueDetail,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code/booking-completed/:booking_code",
    name: "BookingCompleted",
    component: BookingCompleted,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code/sign-in/:invite_code?",
    name: "SignIn",
    component: SignIn,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: "/:merchant_code/terms-service",
    name: "TermServiceDetail",
    component: TermServiceDetail,
    meta: {
      requiresAuth: false
    },
    // beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code/privacy-policy",
    name: "PrivacyPolicyDetail",
    component: PrivacyPolicyDetail,
    meta: {
      requiresAuth: false
    },
    // beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/profile",
    name: "MyProfile",
    component: MyProfile,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: "/:merchant_code?/loyalty-tier-benefit",
    name: "LoyaltyTierBenefit",
    component: LoyaltyTierBenefit,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/admissions",
    name: "Admissions",
    component: Admissions,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/admissions",
    name: "Admissions",
    component: Admissions,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/my-transactions",
    name: "Transactions",
    component: MyTransactions,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/transaction-detail/:transaction_id",
    name: "TransactionDetails",
    component: TransactionDetails,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/home",
    name: "Landing",
    component: Landing,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/reward-detail/:reward_id",
    name: "rewardDetail",
    component: rewardDetail,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/voucher-bundle/:id",
    name: "voucherBundleDetail",
    component: voucherBundleDetail,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  // {
  //   path: "/:merchant_code?/notification",
  //   name: "Notification",
  //   component: Notification,
  //   meta: {
  //     requiresAuth: false
  //   }
  // },
  {
    path: "/:merchant_code?/my-rewards",
    name: "My Rewards",
    component: MyRewards,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/my-campaigns",
    name: "My Campaigns",
    component: MyCampaigns,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/store",
    name: "Store",
    component: Store,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/reward-catalogue",
    name: "Reward Catelogue",
    component: RewardCatalogue,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/invite-friends",
    name: "Invite Friends",
    component: InviteFriends,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/find-branches",
    name: "Find Branches",
    component: FindStores,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code?/contact-us",
    name: "Contact Us",
    component: ContactUs,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code/forget-passcode",
    name: "ForgetPasscode",
    component: ForgotPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/:merchant_code/get-member-ship/:reward_id",
    name: "GetMemberShip",
    component: GetMemberShip,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code/get-member-ship-success",
    name: "GetMemberShipSuccess",
    component: GetMemberShipSuccess,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkMemberPermissions
  },
  {
    path: "/:merchant_code/change-passcode",
    name: "ChangePasscode",
    component: ChangePasscode,
    meta: {
      requiresAuth: false
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to) => {
  const merchatCode = to.params.merchant_code ?? '';
  store.dispatch('setMerchantCode', merchatCode);
  localStorage.setItem('MERCHANT_CODE_' + merchatCode, merchatCode)
})

export default router;
