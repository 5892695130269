<template>
    <div class="page-header align-items-start min-vh-screen my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
            <div class="row">
                <div class="notifications">
                    <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
                        {{ error }}
                    </material-alert>
                </div>
                <div class="col-lg-12 col-md-8 col-12 mx-auto" v-if="isLoadDone">
                    <div class="card-body" style="padding: 0rem 0rem;">
                        <div>
                            <div class="color-common pb-2 font-weight-bolder px-2 pt-2">
                                <h3 :style="`color: ${this.$store.state.labelColor} !important`">FIND
                                    BRANCHES
                                </h3>
                            </div>
                            <div v-if="freeText" class="text-title" style="white-space: pre-wrap">{{ freeText }}</div>
                            <search-input :onChangesearchKeyword="onChangesearchKeyword" placeholder="Find branch"/>
                            <div v-if="branches.length > 0" class="row row-flex" style="margin-bottom:80px">
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 " v-for="(value, index) in branches"
                                    :item="value" :index="index" :key="index">
                                    <div class="border p-3 pt-5 h-100" style="position: relative;">
                                        <div class="col-12 d-flex align-items-center">
                                            <img :src="`${value.file_url}`"
                                                class="avatar-md border-radius-lg col-4 img-branch" alt="user1" />
                                            <div class="col-8 ps-2 text-title">{{ value.branch_name }}</div>
                                        </div>
                                        <div class="col-12 text-time pt-2 text-size-12" v-if="value.operation_days_text">
                                            <div class="col-11" v-for="(item, index) in value.operation_days_text" :key="index" :class="{'font-weight-bold': item.selected}">{{ item.day + ': ' + item.time }}</div>
                                        </div>
                                        <div class="col-12 d-flex align-items-center text-address pt-2 text-size-12">
                                            {{ value.address }}
                                        </div>
                                        <div @click="gotoOrderingOnlineByBranch(value)" class="order-btn-box">
                                            <div class="col-8 text-title" style="padding: 3px 10px !important;">ORDER</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="d-flex justify-content-center p-5" style="background-color: #f0f2f5;">No
                                    branches available
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
        </div>
        <material-loading :active="loaderActive" />
    </div>

</template>

<script>
import { mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import SearchInput from "@/components/SearchInput.vue";
import MerchantService from "../services/merchant.service";
import 'bootstrap/dist/js/bootstrap.bundle'
import MaterialLoading from "@/components/MaterialLoading.vue";
import 'bootstrap/js/dist/modal';
import CryptoJS from 'crypto-js';
import TokenService from "../services/token.service";
import { localStorageName, getMerchantCode } from "@/config/utils";

export default {
    name: "find-branch",
    components: {
        MaterialSnackbar,
        MaterialLoading,
        SearchInput
    },
    data() {
        return {
            isLoadDone: false,
            snackbar: null,
            loaderActive: false,
            merchantCode: "",
            branches: [],
            freeText: '',
            searchKeyword: '',
            searchTimerId: null,
        };
    },
    async created() {
        this.isLoadDone = false;
        await this.getBranches(this.searchKeyword);
        this.isLoadDone = true;
    },
    mounted() {
        const code = getMerchantCode();
        this.merchantCode = code ? `/${code}` : ''
    },
    methods: {
        ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
        closeSnackbar() {
            this.snackbar = null;
        },

        setTimeOutNotification() {
            setTimeout(() => { this.snackbar = null; }, 3000);
        },

        onChangesearchKeyword(event) {
            this.searchKeyword = event.target.value
            clearTimeout(this.searchTimerId);
            this.searchTimerId = setTimeout(() => { this.getBranches(this.searchKeyword) }, 1000)
        },

        async getBranches(search_keyword = '') {
            search_keyword = this.searchKeyword
            this.showLoader()
            await MerchantService.getBranches(search_keyword).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.branches = response.data.data.branches
                        this.freeText = response.data.data.freeText
                    } else {
                        this.snackbar = 'danger'
                        this.message = response.data.result.message
                        this.setTimeOutNotification()
                    }
                    this.hideLoader()
                },
                (error) => {
                    this.snackbar = 'danger'
                    this.message = error
                    this.hideLoader()
                    this.setTimeOutNotification()
                }
            )
        },

        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        encryptData() {
            const user = JSON.parse(localStorage.getItem(localStorageName("member")));
            if(user && user.data && user.data.user && user.data.user.phone_number) {
                var data = {
                    phone_number: user.data.user.phone_number,
                    passcode: this.decryptData(localStorage.getItem(localStorageName("MEMBER_PASSCODE")))
                }
                if (data) {
                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.VUE_APP_KEY_ENCRYPT_MEMBER_INFORMATION).toString();
                    return btoa(encryptedData);
                }
            } else {
                return "";
            }
        },
        // decryptData(encryptedData) {
        //     if (encryptedData) {
        //         try {
        //             const decryptedData = CryptoJS.AES.decrypt(atob(encryptedData), process.env.VUE_APP_KEY_ENCRYPT_MEMBER_INFORMATION).toString(CryptoJS.enc.Utf8);
        //             return decryptedData;
        //         } catch (error) {
        //             return "";
        //         }
        //     }
        // },
        decryptData(encryptedData) {
            if (encryptedData) {
                try {
                    const decryptedData = CryptoJS.AES.decrypt(encryptedData, process.env.VUE_APP_KEY_ENCRYPT_MEMBER_INFORMATION).toString(CryptoJS.enc.Utf8);
                    return decryptedData;
                } catch (error) {
                    return "";
                }
            }
        },
        gotoOrderingOnlineByBranch(item) {
            var memberInformation = this.encryptData();
            if(memberInformation) {
                const token = TokenService.getLocalAccessToken();
                var memberLogin = TokenService.getMember();
                window.open(process.env.VUE_APP_ONLINE_ORDERING_BY_BRANCH_URL+this.merchantCode+"/"+item.branch_code+"?key="+token+"&memberId="+ memberLogin?.data.user.id, '_blank');
            }
        }
    },
};
</script>
<style>
.text-title {
    color: black !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.text-custom {
    color: #344767 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    font-size: 14px !important;
}

.text-time, .text-address {
    color: #6c757d !important;
    font-size: 13px;
}
.img-branch {
    width: 110px !important;
    height: 70px !important;
}
.order-btn-box {
    position: absolute;top: 5px;right: 10px;border: 2px solid orange;border-radius: 5px;
}
.font-weight-bold {
    font-weight: bold;
    color: black;
}
</style>