import store from "@/store";
/**
 * Set localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  return window.localStorage.setItem(name, content)
}
/**
  * Get localStorage
*/
export const getStore = (name) => {
  if (!name) return
  return JSON.parse(window.localStorage.getItem(name))
}
/**
 * Clear localStorage
*/
export const removeItem = (name) => {
  if (!name) return
  return window.localStorage.removeItem(name)
}
/**
 * Validate Email address
 */
export const isValidEmail = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(value) ? false : true
}
/**
 * Back to online ordering
 */
export const backToOnlineOrdering = (merchantCode, branchCode, token, memberId, tableCode, from) => {
  var url = process.env.VUE_APP_ONLINE_ORDERING_BY_BRANCH_URL + merchantCode;

  if (tableCode) {
    url += '/QR/' + branchCode + "/" + tableCode;
  } else {
    url += "/" + branchCode
  }

  url += "?key=" + token + "&memberId=" + memberId + "&from=" + from;

  window.open(url,"_self");
}

export const localStorageName = (name) => {
  const merchantCode = store.state.merchantCode;

  if (merchantCode) {
    return name + '_' + merchantCode;
  }
  
  return name;
}

export const getMerchantCode = () => {
  return store.state.merchantCode;
}
