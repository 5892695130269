<template>
    <div
      class="modal modal-bottom fade"
      id="bottom_modal_memorize"
      tabindex="-1"
      role="dialog"
      aria-labelledby="bottom_modal"
      style="z-index: 10000"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header" style="border: 0; padding: 0.1rem 1rem">
                    <button
                        type="button"
                        class="btn-close text-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row d-flex bd-highlight justify-content-center">
                        <div class="d-flex justify-content-center mt-5">
                            <!-- <img src="../assets/img/logo-ordering.png" class="avatar avatar-md mb-3 border-radius-lg" alt="user1" /> -->
                        </div>
                        <div class="text-center text-size-18" style="color: #222">
                            <span>You have logged in</span><br />
                            <span>using a One Time Pin</span><br />
                            <span>Please reset your passcode now.</span><br />
                        </div>

                        <!-- <div class="text-center text-size-18 mt-4" style="color: #222">
                            <span>DD/MM/YYYY</span>
                        </div>

                        <div class="text-center text-size-18 mt-4" style="color: orange">
                            <strong>{{ birthday }}</strong>
                        </div> -->

                        <material-button :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`" class="mb-2 mt-4" @click="changePasscode()" data-bs-dismiss="modal" aria-label="Close" style="width: 130px;">Reset
                        </material-button>

                        <!-- <div class="text-center mt-4">
                            <a @click="changePasscode()" data-bs-dismiss="modal" aria-label="Close" class="text-decoration-underline text-info">Change passcode</a>
                        </div> -->
                        
                    </div>
                </div>
                <button
                    class="bottom_modal_memorize"
                    style="display: none"
                    data-bs-toggle="modal"
                    data-bs-target="#bottom_modal_memorize"
                >
                    Show Model
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
    import MaterialButton from "@/components/MaterialButton.vue";
    import router from "@/router";
    import moment from 'moment';
    import { localStorageName, getMerchantCode } from "@/config/utils";

    export default {
        name: "MemorizeYourBirthday",
        components: {
            MaterialButton
        },
    props: {
      
    },
    data() {
        return {
            merchantCode: "",
            branchCode: "",
            birthday: 'N/A',
        };
    },
    mounted() {
        const user = JSON.parse(localStorage.getItem(localStorageName("member")));
        if(user && user.data && user.data.user && user.data.user.date_of_birth != "") {
            this.birthday = moment(user.data.user.date_of_birth).format('DD/MM/YYYY');
        }

        const merchantCode = getMerchantCode();
        this.merchantCode = merchantCode ? `/${merchantCode}` : "";
        const branchCode = localStorage.getItem(localStorageName("BRANCH_CODE"), "");
        this.branchCode = branchCode ? `/${branchCode}` : "";
    },
    methods: {
        changePasscode() {
            router.push(this.merchantCode + this.branchCode+"/change-passcode");
        },
        handleOk() {
            var currentUrl = window.location.href;
            var baseUrl = currentUrl.split('?')[0];
            window.location.href = baseUrl;
        }
    },
  };
  </script>
  <style scoped>
  .bg-term {
    width: 350px;
    height: 45px;
    border-radius: 50px;
  }
  .bg-color-common {
    color: #fff;
  }
  .img-terms {
    max-width: 700px;
  }
  
  /* Css model */
  .modal.modal-left .modal-dialog,
  .modal.modal-right .modal-dialog,
  .modal.modal-top .modal-dialog,
  .modal.modal-bottom .modal-dialog {
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  
  /* Left & Right */
  
  .modal.modal-left .modal-dialog,
  .modal.modal-right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 500px;
    max-width: 100%;
    height: 100%;
  }
  
  .modal.modal-left .modal-content,
  .modal.modal-right .modal-content {
    height: 100%;
    overflow-y: auto;
  }
  
  .modal.modal-left .modal-body,
  .modal.modal-right .modal-body {
    padding: 15px 15px 80px;
  }
  
  .modal.modal-left.fade .modal-dialog {
    left: -500px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
  }
  
  .modal.modal-left.fade.show .modal-dialog {
    left: 0;
  }
  
  .modal.modal-right.fade .modal-dialog {
    right: -500px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .modal.modal-right.fade.show .modal-dialog {
    right: 0;
  }
  
  .modal.modal-left .modal-content,
  .modal.modal-right .modal-content,
  .modal.modal-top .modal-content,
  .modal.modal-bottom .modal-content,
  .modal.modal-full .modal-content {
    border-radius: 0;
    border: none;
  }
  
  .modal.modal-left .modal-dialog.modal-sm,
  .modal.modal-right .modal-dialog.modal-sm {
    width: 300px;
  }
  
  .modal.modal-left .modal-dialog.modal-lg,
  .modal.modal-right .modal-dialog.modal-lg {
    width: 800px;
  }
  
  .modal.modal-left .modal-dialog.modal-xl,
  .modal.modal-right .modal-dialog.modal-xl {
    width: 1140px;
  }
  
  /* Top and Bottom */
  
  .modal.modal-top .modal-dialog,
  .modal.modal-bottom .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  
  .modal.modal-top .modal-content,
  .modal.modal-bottom .modal-content {
    height: auto;
    overflow-y: auto;
  }
  
  .modal.modal-top .modal-body,
  .modal.modal-bottom .modal-body {
    padding: 15px 15px;
  }
  
  /* Top */
  
  .modal.modal-top.fade .modal-dialog {
    top: -100%;
    -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
    -o-transition: opacity 0.3s linear, top 0.3s ease-out;
    transition: opacity 0.3s linear, top 0.3s ease-out;
  }
  
  .modal.modal-top.fade.show .modal-dialog {
    top: 0;
  }
  
  /* Bottom */
  
  .modal.modal-bottom.fade .modal-dialog {
    bottom: -100%;
    -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    transition: opacity 0.3s linear, bottom 0.3s ease-out;
  }
  
  .modal.modal-bottom.fade.show .modal-dialog {
    bottom: 0;
  }
  
  .modal.modal-bottom.fade .modal-dialog {
    bottom: -100%;
  }
  
  /* Full Screen */
  
  .modal.modal-full .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  
  .modal.modal-full .modal-content {
    height: 100%;
    overflow-y: auto;
  }
  
  .modal.modal-full .close-modal {
    position: fixed;
    top: 0;
    right: 3rem;
  }
  
  /* Footer */
  
  .modal-footer-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    border-radius: 0;
  }
  
  /* XS */
  
  .modal.modal-left.xs .modal-body,
  .modal.modal-right.xs .modal-body {
    padding: 15px;
  }
  
  .btn-close {
    font-size: 25px;
    color: #222 !important;
  }
  
  .btn-close span {
    color: #222;
  }
  </style>
  