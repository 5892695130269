<template>
  <div v-if="isShowLayout">
  <sidenav :custom_class="color" :class="[isRTL ? 'fixed-end' : 'fixed-start']" v-if="showSidenav" />
  <main class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <!-- nav -->
    <navbar :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''" :minNav="navbarMinimize" v-if="showNavbar" />
    <router-view />
  </main>
  </div>
</template>
<script>
import Sidenav from "./common/Sidenav";
import Navbar from "@/common/Navbars/Navbar.vue";
import { mapMutations, mapState, mapActions } from "vuex";
import $ from "jquery";
import axios from "axios";
import { localStorageName, getMerchantCode } from "@/config/utils";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar
  },
  data() {
    return {
      isShowLayout: false,
    }
  },
  mounted() {
    if (window.innerWidth < 1200) {
      $(".icon-close-sidenav-main").show();
    } else {
      $(".icon-close-sidenav-main").hide();
    }
    var _this = this;
    setTimeout(function() {
      const merchantCode = getMerchantCode();
      if(merchantCode) {
        _this.getSttingMerchant(merchantCode);
      }
    },100)
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    ...mapActions(["setMerchantSetting", "setMerchantLogo", "setBackgroundColor", "setLabelColor", "setButtonColor", "setButtonTextColor"]),
    getSttingMerchant(merchantCode) {
      this.isShowLayout = false;
      axios.get(process.env.VUE_APP_BASE_URL + '/member/get-merchant-setting',
        {
          headers: {
            "MerchantCode": merchantCode
          }
        }
      )
        .then((response) => {
          var dataSetting = response.data;
          if (dataSetting.result.isSuccess) {
            this.setMerchantLogo(dataSetting.data.data_merchant_setting.merchant_logo ?? '');
            this.setBackgroundColor(dataSetting.data.data_merchant_setting.bg_color ?? '#fef7e5');
            this.setLabelColor(dataSetting.data.data_merchant_setting.label_color ?? '#ffbe30');
            this.setButtonColor(dataSetting.data.data_merchant_setting.button_color ?? '#ffbe30');
            this.setButtonTextColor(dataSetting.data.data_merchant_setting.button_text_color ?? '#ffbe30');
            localStorage.setItem(localStorageName('MERCHANT_ID'), dataSetting.data.merchantId ?? 0)
            localStorage.setItem(localStorageName('MERCHANT_BRAND_NAME'), dataSetting.data.merchant_brand_name ?? '')
            localStorage.setItem(localStorageName('IS_CHECK_MERCHANT_CONNECTED_STRIPE'), dataSetting.data.isCheckMerchantConnectedStripe ?? '');
            localStorage.setItem(localStorageName('ALLOW_RESET_PASSCODE_TO_BIRTHDAY'), dataSetting.data.data_merchant_setting.allow_reset_passcode_to_birthday ?? '');
            localStorage.setItem(localStorageName('ALLOW_RESET_PASSCODE_TO_ONE_TIME_OTP'), dataSetting.data.data_merchant_setting.allow_reset_passcode_to_one_time_otp ?? '');
            localStorage.setItem(localStorageName('SENDING_OTP_SIGNUP'), dataSetting.data.data_merchant_setting.sending_otp_signup ?? 0);
            localStorage.setItem(localStorageName('ALLOW_FOREIGNER_PHONE_NUMBER'), dataSetting.data.data_merchant_setting.allow_foreigner_phone_number ?? '');
            this.isShowLayout = true;
          }
        },
          (error) => {
            var status = error.response.status
            console.log(status)
          }
        );
    }
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  }
};
</script>
