<template>
  <div
    class="modal modal-bottom fade"
    id="bottom_modal_terms"
    tabindex="-1"
    role="dialog"
    aria-labelledby="bottom_modal"
    style="z-index: 10000"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border: 0; padding: 0.1rem 1rem">
          <!-- <h5 class="modal-title">Bottom Modal Title</h5> -->
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row d-flex bd-highlight justify-content-center">
            <img src="../assets/img/terms.jpg" class="img-terms" />
            <div class="text-center text-size-18 mt-3" style="color: #222">
              <span
                >Welcome to {{ merchantBrandName }}, another Minty Merchant!</span
              >
            </div>
            <div class="text-center text-size-18" style="color: #222">
              <span
                >To continue, please agree to our terms and policies
                first</span
              >
            </div>
            <div class="d-flex py-2">
              <material-checkbox inputStyle="width: 35px;height: 35px;" @input="changeCheckboxAgree" :checked="iAgree ? true : false" name="iAgree" id="iAgree"
                class="font-weight-light mx-auto">
              </material-checkbox>
            </div>
            <div class="text-center text-size-15">
              <span style="color: #222">Read the</span>
              <span
                style="color: #007bff !important" class="cursor-pointer"
                @click="termServiceDetail()"
              >
                Terms of service</span
              >
              <span style="color: #222"> and </span>
              <span style="color: #007bff !important" class="cursor-pointer"
                @click="privacyPolicyDetail()"
                >Privacy policy</span
              >
            </div>
          </div>
          <div class="mx-auto d-flex mt-3 mb-4">
            <material-button
              class="bg-term mx-auto"
              :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
              @click="agree"
              >I agree
            </material-button>
          </div>
        </div>
      </div>
      <button
        class="bottom_modal_terms"
        style="display: none"
        data-bs-toggle="modal"
        data-bs-target="#bottom_modal_terms"
      >
        Show Model
      </button>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import $ from "jquery";
import { localStorageName, getMerchantCode } from "@/config/utils";

export default {
  name: "TermService",
  components: {
    MaterialButton,
    MaterialCheckbox,
  },
  props: {
    iAgree: {
      type: Boolean,
    },
    phone: {
      type: String,
    },
    changeCheckboxAgree: {
      type: Function,
      default: () => { },
    },
    agree: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      merchantCode: "",
      merchantBrandName: "",
      branchCode: "",
      tableCode: "",
      from: "",
    };
  },
  mounted() {
    const code = getMerchantCode();
    this.merchantCode = code ? `/${code}` : "";
    const brandName = localStorage.getItem(localStorageName("MERCHANT_BRAND_NAME"), "");
    this.merchantBrandName = code ? brandName : "";
    this.branchCode = this.$route.query.branch_code ?? '';
    this.tableCode = this.$route.query.table_code ?? '';
    this.from = this.$route.query.from ?? '';
  },
  methods: {
    termServiceDetail() {
      $(".btn-close").click();
      this.$router.push({ path: this.merchantCode + "/terms-service", query: { phone: this.phone, branch_code: this.branchCode, table_code: this.tableCode, from: this.from } });
    },
    privacyPolicyDetail() {
      $(".btn-close").click();
      this.$router.push({ path: this.merchantCode + "/privacy-policy", query: { phone: this.phone, branch_code: this.branchCode, table_code: this.tableCode, from: this.from } });
    },
  },
};
</script>
<style scoped>
.bg-term {
  width: 350px;
  height: 45px;
  border-radius: 50px;
}
.bg-color-common {
  color: #fff;
}
.img-terms {
  max-width: 700px;
}

/* Css model */
.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog,
.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  max-width: 100%;
  height: 100%;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-left .modal-body,
.modal.modal-right .modal-body {
  padding: 15px 15px 80px;
}

.modal.modal-left.fade .modal-dialog {
  left: -500px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.modal-left.fade.show .modal-dialog {
  left: 0;
}

.modal.modal-right.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.modal-right.fade.show .modal-dialog {
  right: 0;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content,
.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content,
.modal.modal-full .modal-content {
  border-radius: 0;
  border: none;
}

.modal.modal-left .modal-dialog.modal-sm,
.modal.modal-right .modal-dialog.modal-sm {
  width: 300px;
}

.modal.modal-left .modal-dialog.modal-lg,
.modal.modal-right .modal-dialog.modal-lg {
  width: 800px;
}

.modal.modal-left .modal-dialog.modal-xl,
.modal.modal-right .modal-dialog.modal-xl {
  width: 1140px;
}

/* Top and Bottom */

.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content {
  height: auto;
  overflow-y: auto;
}

.modal.modal-top .modal-body,
.modal.modal-bottom .modal-body {
  padding: 15px 15px;
}

/* Top */

.modal.modal-top.fade .modal-dialog {
  top: -100%;
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.modal-top.fade.show .modal-dialog {
  top: 0;
}

/* Bottom */

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.modal-bottom.fade.show .modal-dialog {
  bottom: 0;
}

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
}

/* Full Screen */

.modal.modal-full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.modal-full .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

/* Footer */

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

/* XS */

.modal.modal-left.xs .modal-body,
.modal.modal-right.xs .modal-body {
  padding: 15px;
}

.btn-close {
  font-size: 25px;
  color: #222 !important;
}

.btn-close span {
  color: #222;
}
</style>
