<template>
    <div>
      <button
        style="display: none"
        type="button"
        class="btn-confirm-deactivate-member-modal"
        data-bs-toggle="modal"
        data-bs-target="#bottom_modal_confirm_deactivate_member"
      ></button>
      <div class="modal modal-bottom fade" id="bottom_modal_confirm_deactivate_member" tabindex="-1" role="dialog"
        aria-labelledby="bottom_modal_confirm_deactivate_member" style="z-index: 10000;">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header  d-flex justify-content-end" style="border: 0; padding: 0.1rem 1rem;">
              <span aria-hidden="true" class="btn-close-confirm-modal" style="font-size: 25px; color: #222;" data-bs-dismiss="modal">&times;</span>
            </div>
            <div class="modal-body">
              <div class="row d-flex bd-highlight">
                <div class="text-dark">
                    <div class="text-bold">Deactivate Member Account?</div>
                    <div>What happens when an account is deactivated?</div>
                    <div class="mt-3">
                        <ul>
                            <li>The member will no longer be able to use the exclusive voucher</li>
                            <li>The member will no longer be able to apply the exclusive member price</li>
                            <li>The member will no longer be able to use the earned cashback/points</li>
                        </ul>
                    </div>
                </div>
              </div>
            </div>
            <div class="text-center py-3">
              <div class="row d-flex justify-content-center">
                <material-button class="btn btn-sm btn-secondary mb-3 col-lg-2 col-md-3 col-3 me-1 close-model" data-bs-dismiss="modal">
                  No
                </material-button>
                <material-button class="btn btn-sm mb-3 col-lg-2 col-md-3 col-6 bg-color-common ms-1" data-bs-dismiss="modal"
                  :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`" @click="sycnProfile">
                  Deactivate
                </material-button>
              </div>
            </div>
          </div>
        </div>
        <button class="btn-share-profile-modal" style="display: none" data-bs-toggle="modal"
          data-bs-target="#bottom_modal_share_profile">Success</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "confirm-deactivate-member-modal",
    props: {
      sycnProfile: {
        type: Function,
        default: () => { },
      },
    },
  };
  </script>
  <style scoped>
  .bg-color-common {
    color: #fff;
  }
  
  /* Css model */
  .modal.modal-left .modal-dialog,
  .modal.modal-right .modal-dialog,
  .modal.modal-top .modal-dialog,
  .modal.modal-bottom .modal-dialog {
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  
  /* Left & Right */
  
  .modal.modal-left .modal-dialog,
  .modal.modal-right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 500px;
    max-width: 100%;
    height: 100%;
  }
  
  .modal.modal-left .modal-content,
  .modal.modal-right .modal-content {
    height: 100%;
    overflow-y: none;
  }
  
  .modal.modal-left .modal-body,
  .modal.modal-right .modal-body {
    padding: 15px 15px 80px;
  }
  
  .modal.modal-left.fade .modal-dialog {
    left: -500px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
  }
  
  .modal.modal-left.fade.show .modal-dialog {
    left: 0;
  }
  
  .modal.modal-right.fade .modal-dialog {
    right: -500px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .modal.modal-right.fade.show .modal-dialog {
    right: 0;
  }
  
  .modal.modal-left .modal-content,
  .modal.modal-right .modal-content,
  .modal.modal-top .modal-content,
  .modal.modal-bottom .modal-content,
  .modal.modal-full .modal-content {
    border-radius: 0;
    border: none;
  }
  
  .modal.modal-left .modal-dialog.modal-sm,
  .modal.modal-right .modal-dialog.modal-sm {
    width: 300px;
  }
  
  .modal.modal-left .modal-dialog.modal-lg,
  .modal.modal-right .modal-dialog.modal-lg {
    width: 800px;
  }
  
  .modal.modal-left .modal-dialog.modal-xl,
  .modal.modal-right .modal-dialog.modal-xl {
    width: 1140px;
  }
  
  /* Top and Bottom */
  
  .modal.modal-top .modal-dialog,
  .modal.modal-bottom .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  
  .modal.modal-top .modal-content,
  .modal.modal-bottom .modal-content {
    height: auto;
    overflow-y: none;
  }
  
  .modal.modal-top .modal-body,
  .modal.modal-bottom .modal-body {
    padding: 15px 15px;
  }
  
  /* Top */
  
  .modal.modal-top.fade .modal-dialog {
    top: -100%;
    -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
    -o-transition: opacity 0.3s linear, top 0.3s ease-out;
    transition: opacity 0.3s linear, top 0.3s ease-out;
  }
  
  .modal.modal-top.fade.show .modal-dialog {
    top: 0;
  }
  
  /* Bottom */
  
  .modal.modal-bottom.fade .modal-dialog {
    bottom: -100%;
    -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    transition: opacity 0.3s linear, bottom 0.3s ease-out;
  }
  
  .modal.modal-bottom.fade.show .modal-dialog {
    bottom: 0;
  }
  
  .modal.modal-bottom.fade .modal-dialog {
    bottom: -100%;
  }
  
  /* Full Screen */
  
  .modal.modal-full .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  
  .modal.modal-full .modal-content {
    height: 100%;
    overflow-y: auto;
  }
  
  .modal.modal-full .close-modal {
    position: fixed;
    top: 0;
    right: 3rem;
  }
  
  /* Footer */
  
  .modal-footer-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    border-radius: 0;
  }
  
  /* XS */
  
  .modal.modal-left.xs .modal-body,
  .modal.modal-right.xs .modal-body {
    padding: 15px;
  }
  
  .btn-close {
    font-size: 25px;
    color: #222 !important;
  }
  
  .btn-close span {
    color: #222;
  }
  
  </style>
  