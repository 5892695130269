<template>
    <div :style="styleObject" class="page-header align-items-start min-vh-screen">
        <span class="mask bg-white"></span>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-8 col-12 mx-auto" v-if="isShowLayout">
                    <div class="card-body">
                        <div>
                            <div class="color-common pb-3 font-weight-bolder h4 text-center"
                                :style="`color: ${this.$store.state.labelColor} !important`">
                                CONTACT US
                            </div>
                            <div class="pb-3 font-weight-bolder text-dark title-share-profile text-center"
                                :style="`color: ${this.$store.state.labelColor} !important`">
                                PLEASE FILL IN THE FOLLOWING FORM
                            </div>
                            <div class="input-group input-group-static mb-3">
                                <label for="form-control" class="ms-0"
                                    :style="`color: ${this.$store.state.labelColor} !important`">
                                    Name</label>
                                <div class="form-control p-0 input-none-border" id="form-control">
                                    <material-input extraClass="input-none-border" id="name" name="name" v-model="form.name" :readonly="true"/>
                                </div>
                            </div>
                            <div class="input-group input-group-static mb-3">
                                <label for="form-control" class="ms-0"
                                    :style="`color: ${this.$store.state.labelColor} !important`">Email
                                    Address</label>
                                <div class="form-control p-0 input-none-border" id="form-control">
                                    <material-input extraClass="input-none-border" id="email" type="email" name="email" v-model="form.email"  :readonly="true"/>
                                </div>
                            </div>
                            <div class="input-group input-group-static mb-3">
                                <label for="form-control" class="ms-0"
                                    :style="`color: ${this.$store.state.labelColor} !important`">Mobile
                                    Number</label><span class="text-danger ms-2">*</span>
                                <div class="form-control p-0 d-flex no-bg" id="form-control">
                                    <span class="pt-2 me-3">+65</span>
                                    <material-input extraClass="input-none-border" id="phone_number" type="tel" name="phone_number" class="me-2"
                                        v-model="form.phone_number" :readonly="true"/>
                                </div>
                                <span class="is-invalid" v-if="required.isPhoneRequire">The field is required.</span>
                            </div>
                            <div class="input-group input-group-static mb-3">
                                <label for="form-control" class="ms-0"
                                    :style="`color: ${this.$store.state.labelColor} !important`">Topic of Enquiry</label><span class="text-danger ms-2">*</span>
                                <div class="form-control p-0 mt-1 pb-2 no-bg" id="form-control">
                                    <select id="topic" class="form-select p-2" v-model="form.topic">
                                        <option value="">Select Topic of Enquiry</option>
                                        <option v-for="(value, key) in topicOfEquiry" :key="key"
                                            :value="value">{{
                                                    value
                                            }}</option>
                                    </select>
                                </div>
                                <span class="is-invalid" v-if="required.isTopicOfEquiryRequire">The field is
                                    required.</span>
                            </div>
                            <div class="input-group input-group-static mb-3">
                                <label for="form-control" class="ms-0"
                                    :style="`color: ${this.$store.state.labelColor} !important`">Stores
                                    (if applicable)</label>
                                <div class="form-control p-0 mt-1" id="form-control">
                                    <material-input id="stores" name="stores" v-model="form.stores" />
                                </div>
                            </div>
                            <div class="input-group input-group-static mb-3">
                                <label for="form-control" class="ms-0"
                                    :style="`color: ${this.$store.state.labelColor} !important`">Order
                                    Number (if applicable)</label>
                                <div class="form-control p-0 mt-1" id="form-control">
                                    <material-input id="order_number" name="order_number" v-model="form.order_number" />
                                </div>
                            </div>
                            <div class="input-group input-group-static mb-3">
                                <label for="form-control" class="ms-0"
                                    :style="`color: ${this.$store.state.labelColor} !important`">Message</label><span
                                    class="text-danger ms-2">*</span>
                                <div class="form-control p-0 mt-1" id="form-control">
                                    <material-textarea id="message" name="message" v-model="form.message" />
                                </div>
                                <span class="is-invalid" v-if="required.isMessageRequire">The field is
                                    required.</span>
                            </div>
                            <div class="text-center">
                                <material-button @click="sendMailContactUs()" :disabled="disableBtnSubmit"
                                    class="mb-3 col-lg-4 col-md-5 col-6 bg-green"
                                    :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`">
                                    Submit
                                </material-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
        </div>
        <material-loading :active="loaderActive" />
    </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MemberService from "../services/member.service";
import MaterialLoading from "@/components/MaterialLoading.vue";
import { getMerchantCode } from "@/config/utils";

export default {
    name: "contact-us",
    components: {
        MaterialInput,
        MaterialTextarea,
        MaterialButton,
        MaterialSnackbar,
        MaterialLoading
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                phone_number: '',
                topic: '',
                stores: '',
                order_number: '',
                message: '',
            },
            topicOfEquiry: [
                'Stores',
                'Products',
                'Delivery Services',
                'Membership Portal'
            ],
            loaderActive: false,
            snackbar: null,
            required: {
                isPhoneRequire: false,
                isTopicOfEquiryRequire: false,
                isMessageRequire: false,
            },
            merchantCode: "",
            isShowLayout: false,
            disableBtnSubmit: false
        };
    },
    created() {
        this.getMemberInfo()
    },
    mounted() {
        const code = getMerchantCode();
        this.merchantCode = code ? `/${code}` : ''
        this.getMemberInfo()
    },
    computed: {
        styleObject() {
            return {
                '--label-color': this.$store.state.labelColor,
            };
        },
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
        closeSnackbar() {
            this.snackbar = null;
        },
        getMemberInfo() {
            this.showLoader()
            MemberService.getProfile().then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.memberInfo = response.data.data
                        this.form.name = this.memberInfo.name ? this.memberInfo.name : ''
                        this.form.email = this.memberInfo.email ? this.memberInfo.email : ''
                        this.form.phone_number = this.memberInfo.phone_number ? this.memberInfo.phone_number : ''
                        this.isShowLayout = true
                    } else {
                        this.snackbar = 'danger'
                        this.message = response.data.result.message
                        this.setTimeOutNotification()
                    }
                    this.hideLoader()
                },
                (error) => {
                    this.snackbar = 'danger'
                    this.message = error
                    this.setTimeOutNotification()
                    this.hideLoader()
                }
            );
        },
        sendMailContactUs() {
            this.disableBtnSubmit = true
            this.closeSnackbar()
            if (this.processValidate()) {
                this.showLoader()
                MemberService.sendMailContact(this.form).then(
                    (response) => {
                        console.log('response', response)
                        if (response.data.result.isSuccess) {
                            this.snackbar = 'success'
                            this.message = "Query submitted successfully! We will get back to you soon!"
                            this.setTimeOutNotification()
                        } else {
                            this.snackbar = 'danger'
                            this.message = response.data.result.message
                            this.setTimeOutNotification()
                        }
                        this.hideLoader()
                        this.disableBtnSubmit = false
                    },
                    (error) => {
                        this.snackbar = 'danger'
                        this.message = error
                        this.setTimeOutNotification()
                        this.hideLoader()
                        this.disableBtnSubmit = false
                    }
                );
            } else {
                this.disableBtnSubmit = false
            }
        },
        setTimeOutNotification() {
            setTimeout(() => { this.snackbar = null; }, 3000);
        },
        resetRequired() {
            this.required.isPhoneRequire = false;
            this.required.isTopicOfEquiryRequire = false;
            this.required.isMessageRequire = false;
        },
        processValidate() {
            this.resetRequired();
            var isValid = true;
            if (this.form.phone_number == '') {
                this.required.isPhoneRequire = true;
                isValid = false;
            }
            if (this.form.topic == '') {
                this.required.isTopicOfEquiryRequire = true;
                isValid = false;
            }
            if (this.form.message == '') {
                this.required.isMessageRequire = true;
                isValid = false;
            }
            return isValid;
        },
        showLoader() {
            this.loaderActive = true;
        },

        hideLoader() {
            this.loaderActive = false;
        },
    },
};
</script>
<style>
.pl-0 {
    padding-left: 0px !important;
}

.monthly-group .form-check {
    padding-left: 12px !important;
}

.btn-share-profile {
    color: #007BFF !important;
    text-decoration: underline !important;
}

.title-share-profile {
    font-size: 13px;
    font-weight: 600;
}
</style>