<template>
    <div :style="styleObject" class="page-header align-items-start min-vh-100">
        <span class="mask bg-white"></span>
        <div class="container">
            <div class="row">
                <div class="notifications">
                    <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
                    {{ error }}
                    </material-alert>
                </div>
                <div class="col-lg-4 col-md-8 col-12 mx-auto">
                    <div class="d-flex justify-content-center mt-5">
                    <!-- <img src="../assets/img/logo-ordering.png" class="avatar avatar-md mb-3 border-radius-lg" alt="user1" /> -->
                    </div>
                    <div class="card-body">
                        <div class="mt-7 mb-2">
                        <form role="form" class="text-start">
                            <div class="text-center">
                                <span>Create a 4-digit passcode</span><br />
                                <span>You will be asked for this passcode in every login</span>
                            </div>
                            <div id="otp" class="inputs d-flex flex-row justify-content-center mt-4" style="align-items: center;">
                                <v-otp-input
                                    ref="inputEnterPasscode"
                                    input-classes="otp-input"
                                    separator=""
                                    :num-inputs="4"
                                    :should-auto-focus="true"
                                    :is-input-num="true"
                                    :conditionalClass="['one', 'two', 'three', 'four']"
                                    :placeholder="['', '', '', '']"
                                    @on-complete="handleOnCompleteAlreadyPasscode"
                                    v-model="form.passcode"
                                />
                            </div>
        
                            <div class="text-center mt-4">
                                <span>Confirm your passcode</span>
                                <span>Input your passcode once again</span>
                            </div>
                            <div id="otp" class="inputs d-flex flex-row justify-content-center mt-4" style="align-items: center;">
                                <v-otp-input
                                    ref="inputEnterPasscodeConfirm"
                                    input-classes="otp-input"
                                    separator=""
                                    :num-inputs="4"
                                    :should-auto-focus="false"
                                    :is-input-num="true"
                                    :conditionalClass="['one', 'two', 'three', 'four']"
                                    :placeholder="['', '', '', '']"
                                    @on-complete="handleOnCompleteAlreadyPasscode1"
                                    v-model="form.passcode1"
                                />
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
        </div>
    </div>
</template>
  
<script>
    import MaterialAlert from "@/components/MaterialAlert.vue";
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import VOtpInput from "vue3-otp-input";
    import TokenService from "../services/token.service";
    import router from "@/router";
    import { localStorageName, getMerchantCode } from "@/config/utils";

    export default {
        name: "Changepasscode",
        components: {
            MaterialAlert,
            MaterialSnackbar,
            VOtpInput
        },
        computed: {
            styleObject() {
            return {
                "--label-color": this.$store.state.labelColor,
            };
            },
        },
        data() {
            return {
                form: {
                    phone_code: '65',
                    phone_number: '',
                    passcode: '',
                    passcode1: '',
                },
                snackbar: null,
                error: '',
                merchantCode: "",
                branchCode: ""
            };
        },
        mounted() {
            const merchantCode = getMerchantCode();
            this.merchantCode = merchantCode ? `/${merchantCode}` : "";
            const branchCode = localStorage.getItem(localStorageName("BRANCH_CODE"), "");
            this.branchCode = branchCode ? `/${branchCode}` : "";

            const token = TokenService.getLocalAccessToken();
            if (!token) {
                router.push(this.merchantCode + this.branchCode+ '/sign-in');
            }

            const user = JSON.parse(localStorage.getItem(localStorageName("member")));
            if(user && user.data && user.data.user) {
                this.form.phone_number = user?.data.user.phone_number;
            }
        },
        methods: {
            handleLogin: function () {
                this.successful = false;
                this.loading = true;
                this.$store.dispatch("auth/changePasscode", this.form).then(
                    (data) => {
                    if (data != undefined && !data.result.isSuccess) {
                        this.snackbar = 'danger'
                        this.message = data.result.message
                        this.setTimeOutNotification();
                    } else {
                        this.snackbar = 'success'
                        this.message = "Updated successfully!"
                        setTimeout(() => {
                            router.push(this.merchantCode+"/home");
                        }, 1500);
                    }
                    },
                    (error) => {
                    console.log('ERROR' + error)
                    }
                );
            },
            closeSnackbar() {
                this.snackbar = null;
            },
            setTimeOutNotification() {
                setTimeout(() => {
                    this.snackbar = null;
                }, 3000);
            },
            handleOnCompleteAlreadyPasscode: function (value) {
                this.form.passcode = value;
                if(this.form.passcode != "" && this.form.passcode1 != "") {
                    if(this.form.passcode !== this.form.passcode1) {
                        this.snackbar = 'danger'
                        this.message = "Passcode not match!"
                        this.setTimeOutNotification();
                        return;
                    }
                    this.handleLogin();
                }
                this.$nextTick(() => {
                    this.$refs.inputEnterPasscodeConfirm.$el.querySelector('input').focus();
                });
            },
            handleOnCompleteAlreadyPasscode1: function (value) {
                this.form.passcode1 = value;
                if(this.form.passcode != "" && this.form.passcode1 != "") {
                    if(this.form.passcode !== this.form.passcode1) {
                        this.snackbar = 'danger'
                        this.message = "Passcode not match!"
                        this.setTimeOutNotification();
                        return;
                    }
                    this.handleLogin();
                }
            },
        },
    };
</script>
<style>
    .otp-input {
        width: 40px;
        height: 40px;
        padding: 5px;
        margin: 0 10px;
        font-size: 20px;
        border: none;
        /* border-bottom: 2px solid green; */
        border-bottom: 2px solid var(--label-color);
        text-align: center;
        border-radius: 0px !important;
        background: none;
    }
    
    .form-control:disabled,
    .form-control[readonly] {
        padding: 2px !important;
    }
    
    .text-green {
        color: #ffbe30;
    }
    
    .otp-input:focus {
        outline: none !important;
    }
    
    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input::placeholder {
        font-size: 15px;
        text-align: center;
        font-weight: 600;
    }
</style>
  